import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

export default function Section() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: {
            reference: {
              in: [
                "4-23-0-0"
                "4-24-1-0"
                "7-7-4-0"
                "7-8-0-0"
                "2-1-0-0"
                "2-2-0-0"
                "4-21-0-0"
                "4-26-25-0"
              ]
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              reference
              slug
              embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              thumbnailText
              excerpt
              title
            }
          }
        }
      }
    }
  `);

  return (
    <div className="bg-white px-4 sm:px-6 lg:pb-12">
      <div className="mx-auto py-6 sm:py-6 lg:max-w-7xl lg:px-4">
        <div className="grid grid-cols-2 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-8">
          {data.allMdx.edges.map((page) => (
            <a
              key={page.node.frontmatter.reference}
              href={page.node.frontmatter.slug}
              className="group"
            >
              <div className="w-full bg-gray-200 rounded-lg overflow-hidden">
                <GatsbyImage
                  className="w-full h-full object-center object-cover group-hover:opacity-75"
                  alt={page.node.frontmatter.thumnailText}
                  image={getImage(page.node.frontmatter.embeddedImagesLocal[0])}
                />
              </div>
              <h3 className="mt-1 text-lg font-medium text-gray-900">
                {page.node.frontmatter.title}
              </h3>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
